import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ApiKeyListComponent } from '../../components/api-key-list/api-key-list.component';

@Component({
	selector: 'app-dashboard',
	standalone: true,
	imports: [
		ApiKeyListComponent
	],
	templateUrl: './dashboard.component.html',
	styleUrl: './dashboard.component.less',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardPageComponent {

}
