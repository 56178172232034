import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, signal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { ApiKey } from '../../models/api-key.model';
import { finalize, Observable, of, startWith, Subject, switchMap } from 'rxjs';
import { ApiKeyService } from '../../services/api-key.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MatRippleModule } from '@angular/material/core';
import { TranslocoModule } from '@jsverse/transloco';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
	selector: 'app-api-key-list',
	standalone: true,
	imports: [
		MatIconModule,
		MatButtonModule,
		AsyncPipe,
		NgIf,
		MatProgressSpinnerModule,
		MatTableModule,
		ClipboardModule,
		MatRippleModule,
		TranslocoModule,
		MatTooltipModule
	],
	templateUrl: './api-key-list.component.html',
	styleUrl: './api-key-list.component.less',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ApiKeyListComponent {

	private readonly _apiKeyService = inject(ApiKeyService);

	displayedColumns: string[] = ['value', 'action'];

	data$: Observable<ApiKey[]> = of([]);
	loadingList = signal(true);

	refresher: Subject<void> = new Subject<void>();

	constructor() {
		this.data$ = this.refresher.pipe(
			startWith({}),
			switchMap(() => {
				this.loadingList.set(true);
				return this._apiKeyService.getAll()
					.pipe(
						finalize(() => {
							this.loadingList.set(false);
						})
					);
			})
		);
	}

	createApiKey() {
		this._apiKeyService.create().subscribe(() => {
			this.refresher.next();
		});
	}

	deleteApiKey(row: ApiKey) {
		this._apiKeyService.delete(row.id).subscribe(() => {
			this.refresher.next();
		});
	}
}
