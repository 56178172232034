import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { UploadedImage } from '../models/uploaded-image.models';
import { map, Observable } from 'rxjs';
import { ApiPagedResponse, ApiResponse } from '../../../core/models/api/api.models';
import { WORKSPACE_API_URL_PREFIX } from '../../../shared/constants/api.constants';
import { UploadImageByUrlRequest } from '../models/upload-image-by-url-request.model';
import { plainToInstance } from 'class-transformer';

@Injectable({
	providedIn: 'root'
})
export class ImageLibraryService {

	private readonly _http = inject(HttpClient);

	public getUploadedImages(pageNumber: number, pageSize: number): Observable<ApiPagedResponse<UploadedImage>> {
		let params = new HttpParams()
			.set('pageNumber', pageNumber)
			.set('pageSize', pageSize);
		return this._http.get<ApiPagedResponse<UploadedImage>>(`${WORKSPACE_API_URL_PREFIX}/api/image-library/images`, { params: params });
	}

	public uploadImageByUrl(request: UploadImageByUrlRequest): Observable<UploadedImage> {
		const body = request;
		return this._http.post<ApiResponse<UploadedImage>>(`${WORKSPACE_API_URL_PREFIX}/api/upload/image/url`, body).pipe(
			map(v => plainToInstance(UploadedImage, v.data))
		);
	}

}
