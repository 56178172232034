import { ChangeDetectionStrategy, Component, effect, EventEmitter, Inject, inject, Input, Output, signal } from '@angular/core';
import { ImageUploadComponent } from '../image-upload/image-upload.component';
import { UploadedImage } from '../../models/uploaded-image.models';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { finalize, lastValueFrom, map, Observable, of, startWith, Subject, switchMap } from 'rxjs';
import { MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { ImageLibraryService } from '../../services/image-library.service';
import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TranslocoModule } from '@jsverse/transloco';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { SuppressAnyErrorStateMatcher } from '../../../../shared/material/error-state-matchers/suppress-any-error';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { UploadImageByUrlRequest } from '../../models/upload-image-by-url-request.model';

export interface ImageGridParams {
	isSelectable: boolean;
}

@Component({
	selector: 'app-image-grid',
	standalone: true,
	imports: [
		ImageUploadComponent,
		NgScrollbarModule,
		MatPaginatorModule,
		AsyncPipe,
		NgIf,
		NgFor,
		MatProgressSpinnerModule,
		TranslocoModule,
		MatButtonModule,
		MatFormFieldModule,
		MatInputModule,
		FormsModule,
		ReactiveFormsModule,
		MatIconModule,
		MatTooltipModule
	],
	templateUrl: './image-grid.component.html',
	styleUrl: './image-grid.component.less',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImageGridComponent {

	private readonly _dialogRef = inject(DialogRef<UploadedImage>);
	private readonly _imageLibraryService = inject(ImageLibraryService);

	@Input() params!: ImageGridParams;
	@Output() select = new EventEmitter<UploadedImage>();

	data$: Observable<UploadedImage[]> = of([]);
	pageSize = 24;
	totalRecords = 0;
	loadingList = signal(true);
	pageIndex: number = 0;
	uploadingByUrl = signal(false);

	refresher: Subject<void> = new Subject<void>();

	suppressAnyErrorStateMatcher = new SuppressAnyErrorStateMatcher();

	readonly uploadByUrlForm = new FormGroup({
		url: new FormControl<string | null>(null, [Validators.required])
	});

	constructor(@Inject(DIALOG_DATA) data?: ImageGridParams) {
		if (data) {
			this.params = data;
		}

		this.data$ = this.refresher.pipe(
			startWith({}),
			switchMap(() => {
				this.loadingList.set(true);
				return this._imageLibraryService.getUploadedImages(this.pageIndex + 1, this.pageSize)
					.pipe(
						finalize(() => {
							this.loadingList.set(false);
						})
					);
			}),
			map(response => {
				this.totalRecords = response.totalRecords;
				return response.data;
			})
		);

		effect(() => {
			const uploadingByUrl = this.uploadingByUrl();
			if (uploadingByUrl) {
				this.uploadByUrlForm.disable();
			}
			else {
				this.uploadByUrlForm.enable();
			}
		});
	}

	onImageUpload(result: UploadedImage) {
		this.refresher.next();
	}

	onImageClick(image: UploadedImage) {
		if (this._dialogRef) {
			this._dialogRef.close(image);
			return;
		}
		this.select.emit(image);
	}

	async uploadByUrl() {
		if (!this.uploadByUrlForm.valid) {
			return;
		}
		try {
			this.uploadingByUrl.set(true);
			await lastValueFrom(this._imageLibraryService.uploadImageByUrl(this.uploadByUrlForm.value as UploadImageByUrlRequest));
			this.uploadByUrlForm.reset();
			this.refresher.next();
		}
		finally {
			this.uploadingByUrl.set(false);
		}
	}

	close() {
		this._dialogRef.close();
	}

	onPage(event: PageEvent) {
		this.pageIndex = event.pageIndex;
		this.refresher.next();
	}
}
