import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { UserProfile } from '../../../core/models/users/user-profile';
import { ApiResponse } from '../../../core/models/api/api.models';
import { WORKSPACE_API_URL_PREFIX } from '../../../shared/constants/api.constants';

@Injectable({
	providedIn: 'root'
})
export class UserService {

	private readonly _http = inject(HttpClient);

	getProfile(): Observable<UserProfile> {
		return this._http.get<ApiResponse<UserProfile>>(`${WORKSPACE_API_URL_PREFIX}/api/users/profile`).pipe(
			map(v => v.data)
		);
	}
}
