import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, inject, InjectionToken, TemplateRef } from '@angular/core';
import { SafeHtmlPipe } from '../../pipes/safe-html.pipe';
import { trigger, transition, style, animate } from '@angular/animations';

export type TooltipData = string | TemplateRef<any>;
export const TOOLTIP_DATA = new InjectionToken<TooltipData>("Data to display in tooltip");

@Component({
	selector: 'app-tooltip-container',
	standalone: true,
	imports: [
		NgTemplateOutlet,
		SafeHtmlPipe
	],
	templateUrl: './tooltip-container.component.html',
	styleUrl: './tooltip-container.component.less',
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [
		trigger('animation', [
			transition(':enter', [
				style({
					opacity: 0,
					transform: 'scale(0.8)'
				}),
				animate('150ms ease-in-out', style({
					opacity: 1,
					transform: 'scale(1)'
				}))
			]),
			transition(':leave', [
				style({
					opacity: 1,
					transform: 'scale(1)'
				}),
				animate('150ms ease-in-out', style({
					opacity: 0,
					transform: 'scale(0.8)'
				}))
			])
		])
	]
})
export class TooltipContainerComponent {
	tooltipData = inject<TooltipData>(TOOLTIP_DATA);

	@HostBinding('@animation') animationState = true;

	isString(value: TooltipData): value is string {
		return typeof value === "string";
	}

	isTemplate(value: TooltipData): value is TemplateRef<void> {
		return this.tooltipData instanceof TemplateRef;
	}
}
