import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, effect, inject, input, output, signal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { AssistantsService } from '../../services/assistants.service';
import { EnhancePromptRequest, EnhancePromptTargetStyleEnum } from '../../models/magic-prompt.models';
import { TranslocoModule } from '@jsverse/transloco';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { GlobalSignalingService } from '../../../../shared/services/signaling/global-signaling.service';

@Component({
	selector: 'app-magic-prompt',
	standalone: true,
	imports: [
		NgIf,
		MatButtonModule,
		MatMenuModule,
		TranslocoModule,
		MatProgressSpinnerModule
	],
	templateUrl: './magic-prompt.component.html',
	styleUrl: './magic-prompt.component.less',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class MagicPromptComponent {

	private readonly _assistantsService = inject(AssistantsService);
	private readonly _signalingService = inject(GlobalSignalingService);

	prompt = input<string | null>();
	enhanced = output<string>();

	TargetStyleEnum = EnhancePromptTargetStyleEnum;

	disabled = computed(() => {
		return (this.prompt()?.length ?? 0) === 0 || this.loading();
	});

	loading = signal(false);

	constructor() {
		effect(() => {
			const result = this._signalingService.promptEnhancementResult();
			if (!result) {
				return;
			}
			this.enhanced.emit(result.finalPrompt);
			this.loading.set(false);
		}, {
			allowSignalWrites: true
		});
	}

	enhancePrompt(targetStyleId: EnhancePromptTargetStyleEnum) {
		const request = <EnhancePromptRequest>{
			prompt: this.prompt(),
			targetStyleId
		};
		this.loading.set(true);
		this._assistantsService.enhancePrompt(request).subscribe();
	}
}
