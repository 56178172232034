import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, Component, Inject, inject, signal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

@Component({
	selector: 'app-image-modal-wrapper',
	standalone: true,
	imports: [
		MatIconModule,
		MatButtonModule
	],
	templateUrl: './image-modal-wrapper.component.html',
	styleUrl: './image-modal-wrapper.component.less',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImageModalWrapperComponent {

	private readonly _dialogRef = inject(DialogRef<void>);

	imageUrl = signal<string | null>(null);

	constructor(@Inject(DIALOG_DATA) imageUrl: string) {
		this.imageUrl.set(imageUrl);
	}

	close() {
		this._dialogRef.close();
	}
}
