<ng-container *transloco="let t; prefix: 'assistants.components.magicPrompt'">
	<div class="wrapper">
		<button type="button" mat-button color="primary" [disabled]="disabled()" [matMenuTriggerFor]="targetStyleMenu">
			<img class="icon" [class.disabled]="disabled" src="/icons/magic-wand-01.png" />
			<span>{{ t("magicPromptButton") }}</span>
		</button>
		<mat-menu #targetStyleMenu="matMenu">
			<button type="button" mat-menu-item (click)="enhancePrompt(TargetStyleEnum.Undefined)">
				<span>{{ t("targetStyles.undefined") }}</span>
			</button>
			<button type="button" mat-menu-item (click)="enhancePrompt(TargetStyleEnum.Photorealistic)">
				<span>{{ t("targetStyles.photorealistic") }}</span>
			</button>
			<button type="button" mat-menu-item (click)="enhancePrompt(TargetStyleEnum.Illustration)">
				<span>{{ t("targetStyles.illustration") }}</span>
			</button>
		</mat-menu>
		<div *ngIf="loading()" class="loading-wrapper">
			<mat-progress-spinner mode="indeterminate" diameter="24"></mat-progress-spinner>
		</div>
	</div>
</ng-container>
