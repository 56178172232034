@if(task()?.result?.generatedImages) {
<div class="task-wrapper">
	<div class="header-wrapper">
		<div class="caption mat-body-small">{{ prompt(task()!) }}</div>
	</div>
	<div class="image-list-wrapper">
		@for (image of task()!.result!.generatedImages; track image.id; let idx = $index) {
		<div class="image-wrapper">
			<img class="image" [src]="image.mediaLink" [appViewImage]="image.mediaLink" />
		</div>
		}
	</div>
</div>
} @else if(task()){
<div class="task-wrapper in-progress progress-border">
	<div class="header-wrapper">
		<div class="caption mat-body-small">{{ task()?.prompt }}</div>
		<div class="status mat-label-small">{{ statusText() }}</div>
	</div>
	@if(generalStatus() === "pending") {
	<ngx-skeleton-loader appearance="line" [theme]="{ width: '100%', height: '120px', cursor: 'inherit', 'border-radius': '8px' }" />
	} @else {
	<img class="error-image" src="/images/error-01.png" />
	<!-- <div class="image-list-wrapper">
		<div class="image-wrapper">
			<img class="image" src="/images/error-01.png" />
		</div>
	</div> -->
	}
</div>
}
