import { Expose } from "class-transformer";
import { ImageFormatEnum } from "../../imaging/models/enumerations";

export class UploadedImage {
	@Expose() id!: number;
	@Expose() sourceFileName!: string;
	@Expose() name!: string;
	@Expose() mediaLink!: string;
	@Expose() imageFormatId!: ImageFormatEnum;
	@Expose() width!: number;
	@Expose() height!: number;
}
