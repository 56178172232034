import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { ApiKey } from '../models/api-key.model';
import { map, Observable } from 'rxjs';
import { ApiResponse } from '../../../core/models/api/api.models';
import { WORKSPACE_API_URL_PREFIX } from '../../../shared/constants/api.constants';

@Injectable({
	providedIn: 'root'
})
export class ApiKeyService {

	private readonly _http = inject(HttpClient);

	getAll(): Observable<ApiKey[]> {
		return this._http.get<ApiResponse<ApiKey[]>>(`${WORKSPACE_API_URL_PREFIX}/api/users/api-keys`).pipe(
			map(v => v.data)
		);
	}

	create(): Observable<ApiKey> {
		const body = {};
		return this._http.post<ApiResponse<ApiKey>>(`${WORKSPACE_API_URL_PREFIX}/api/users/api-keys`, body).pipe(
			map(v => v.data)
		);
	}

	delete(id: number): Observable<void> {
		return this._http.delete<void>(`${WORKSPACE_API_URL_PREFIX}/api/users/api-keys/${id}`);
	}
}
