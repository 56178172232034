import { AssistantTaskStatusEnum } from "./enumerations";

export enum EnhancePromptTargetStyleEnum {
	Undefined = 0,
	Photorealistic = 1,
	Illustration = 2
}

export interface EnhancePromptRequest {
	prompt: string;
	targetStyleId: EnhancePromptTargetStyleEnum;
}

export interface PromptEnhancement {
	id: number;
	initialPrompt: string;
	finalPrompt?: string;
	targetStyleId: EnhancePromptTargetStyleEnum;
	statusId: AssistantTaskStatusEnum;
}
