<mat-toolbar>
	<div class="section main">
		<div class="logo-wrapper">
			<img class="logo" src="/images/logo-icon-stroke-01.svg" />
			<span>Fusara AI</span>
		</div>
	</div>
	<div class="section">
		<app-language-select></app-language-select>
		<app-dropdown></app-dropdown>
	</div>
</mat-toolbar>
