<ng-container *transloco="let t; prefix: 'users.pages.profile'">
	<div class="wrapper" *ngIf="profile$ | async as profile">
		<mat-card appearance="raised" class="login-wrapper">
			<mat-card-header>
				<mat-card-title>{{ t("title") }}</mat-card-title>
			</mat-card-header>
			<mat-card-content>
				<div class="details-wrapper">
					<div class="mat-title-large">{{ profile.firstName }} {{ profile.lastName }}</div>
					<div class="mat-body-medium">{{ profile.email }}</div>
				</div>
			</mat-card-content>
			<mat-card-actions [align]="'end'">
				<button mat-button color="primary" (click)="logout()">
					<mat-icon>logout</mat-icon>
					{{ t("buttonLogout") }}
				</button>
			</mat-card-actions>
		</mat-card>
	</div>
</ng-container>
