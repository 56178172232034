import { Overlay, OverlayRef, PositionStrategy, ScrollStrategy } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Directive, ElementRef, HostListener, inject, Injector, Input, ViewContainerRef } from '@angular/core';
import { TOOLTIP_DATA, TooltipContainerComponent, TooltipData } from '../components/tooltip-container/tooltip-container.component';

@Directive({
	selector: '[appTooltip]',
	standalone: true
})
export class TooltipDirective {

	private element = inject<ElementRef<HTMLElement>>(ElementRef);
	private overlay = inject(Overlay);
	private viewContainer = inject(ViewContainerRef);

	@Input() appTooltip!: TooltipData;
	@Input() closeDelay = 500;

	private overlayRef: OverlayRef | null = null;

	private timerHandle?: number;

	@HostListener("mouseenter")
	@HostListener("focus")
	showTooltip(): void {

		if (this.timerHandle) {
			clearTimeout(this.timerHandle);
		}

		if (this.overlayRef?.hasAttached() === true) {
			return;
		}

		this.attachTooltip();
	}

	@HostListener("mouseleave")
	@HostListener("blur")
	hideTooltip(): void {
		if (this.overlayRef?.hasAttached() === true) {
			this.setTimeout();
		}
	}

	clearTimeout() {
		if (this.timerHandle) {
			clearTimeout(this.timerHandle);
		}
	}

	setTimeout() {
		this.clearTimeout();
		this.timerHandle = window.setTimeout(() => {
			this.overlayRef?.detach();
		}, this.closeDelay);
	}

	ngOnDestroy(): void {
		this.clearTimeout();
		this.overlayRef?.dispose();
	}

	private attachTooltip(): void {
		if (this.overlayRef === null) {
			const positionStrategy = this.getPositionStrategy();
			const scrollStrategy = this.getScrollStrategy();

			this.overlayRef = this.overlay.create({ positionStrategy, scrollStrategy });

			this.overlayRef.overlayElement?.addEventListener('mouseenter', () => {
				this.clearTimeout();
			});

			this.overlayRef.overlayElement?.addEventListener('mouseleave', () => {
				this.setTimeout();
			});
		}

		const injector = Injector.create({
			providers: [
				{
					provide: TOOLTIP_DATA,
					useValue: this.appTooltip,
				},
			],
		});
		const component = new ComponentPortal(TooltipContainerComponent, this.viewContainer, injector);
		this.overlayRef.attach(component);
	}

	private getPositionStrategy(): PositionStrategy {
		return this.overlay
			.position()
			.flexibleConnectedTo(this.element)
			.withPositions([
				{
					originX: "center",
					originY: "top",
					overlayX: "center",
					overlayY: "bottom",
					panelClass: "top",
				},
				{
					originX: "center",
					originY: "bottom",
					overlayX: "center",
					overlayY: "top",
					panelClass: "bottom",
				},
			]);
	}

	private getScrollStrategy(): ScrollStrategy {
		return this.overlay.scrollStrategies.reposition();
	}
}
