<ng-container *transloco="let t; prefix: 'imaging.components.generateImageForm'">
	<div class="wrapper">
		<form [formGroup]="form" (ngSubmit)="submit()">
			<mat-card appearance="raised">
				<mat-card-header>
					<mat-card-title>{{ t("title") }}</mat-card-title>
				</mat-card-header>
				<div class="progress-wrapper">
					<mat-progress-bar mode="indeterminate" *ngIf="loading()"></mat-progress-bar>
				</div>
				<mat-card-content>
					<div class="form-body">
						<mat-radio-group formControlName="__type" class="row" (change)="onTypeChange()">
							<mat-radio-button [value]="ImagingProviderEnum.Midjourney">Midjourney</mat-radio-button>
							<mat-radio-button [value]="ImagingProviderEnum.SD">Stable Diffusion</mat-radio-button>
							<mat-radio-button [value]="ImagingProviderEnum.Upscale">Upscale</mat-radio-button>
						</mat-radio-group>
						<ng-container *ngIf="form.controls.__type.value === ImagingProviderEnum.Upscale">
							<div class="upscale-section-wrapper">
								<mat-form-field appearance="outline" subscriptSizing="dynamic">
									<mat-label>{{ t("upscale.scaleFactorLabel") }}</mat-label>
									<input type="text" formControlName="scaleFactor" matInput [errorStateMatcher]="suppressAnyErrorStateMatcher" [placeholder]="t('upscale.scaleFactorPlaceholder')" />
								</mat-form-field>
								<button type="button" mat-raised-button (click)="selectImageToUpscale()">
									<mat-icon class="material-symbols-outlined">image</mat-icon>
									{{ t("upscale.selectImageButton") }}
								</button>
								<img *ngIf="imageToUpscaleUrl()" [src]="imageToUpscaleUrl()" class="image-to-upscale-preview" [appViewImage]="imageToUpscaleUrl()!" />
							</div>
						</ng-container>
						<ng-container *ngIf="form.controls.__type.value !== ImagingProviderEnum.Upscale">
							<mat-form-field appearance="outline" floatLabel="always" subscriptSizing="dynamic">
								<mat-label>{{ t("promptLabel") }}</mat-label>
								<textarea formControlName="prompt" matInput [errorStateMatcher]="suppressAnyErrorStateMatcher" [placeholder]="t('promptPlaceholder')" app-autofocus> </textarea>
								<mat-error>{{ t("promptError") }}</mat-error>
							</mat-form-field>
							<div class="row">
								<button type="button" mat-icon-button (click)="toggleTaskDetails()"><mat-icon>tune</mat-icon></button>
								<button type="button" mat-icon-button (click)="resetForm()"><mat-icon class="material-symbols-outlined">reset_settings</mat-icon></button>
								<app-magic-prompt [prompt]="form.controls.prompt.value" (enhanced)="form.controls.prompt.setValue($event)"></app-magic-prompt>
							</div>
							<div *ngIf="taskDetails()" class="task-details-wrapper">
								<mat-form-field appearance="outline" floatLabel="always" subscriptSizing="dynamic">
									<mat-label>{{ t("negativePromptLabel") }}</mat-label>
									<textarea formControlName="negativePrompt" matInput [errorStateMatcher]="suppressAnyErrorStateMatcher" [placeholder]="t('negativePromptPlaceholder')"> </textarea>
								</mat-form-field>
								<ng-container *ngIf="form.controls.__type.value === ImagingProviderEnum.Midjourney">
									<div class="grid-row">
										<div class="control-row">
											<label class="cell">{{ t("midjourney.stylizeLabel") }}</label>
											<app-hint-trigger [description]="t('midjourney.stylizeDescription')"></app-hint-trigger>
											<div class="cell main">
												<mat-slider min="0" max="1000" step="100" discrete>
													<input formControlName="stylize" matSliderThumb />
												</mat-slider>
											</div>
										</div>
										<div class="control-row">
											<label class="cell">{{ t("midjourney.weirdLabel") }}</label>
											<app-hint-trigger [description]="t('midjourney.weirdDescription')"></app-hint-trigger>
											<div class="cell main">
												<mat-slider min="0" max="3000" step="100" discrete>
													<input formControlName="weird" matSliderThumb />
												</mat-slider>
											</div>
										</div>
										<div class="control-row">
											<label class="cell">{{ t("midjourney.chaosLabel") }}</label>
											<app-hint-trigger [description]="t('midjourney.chaosDescription')"></app-hint-trigger>
											<div class="cell main">
												<mat-slider min="0" max="100" step="10" discrete>
													<input formControlName="chaos" matSliderThumb />
												</mat-slider>
											</div>
										</div>
									</div>
									<div class="grid-row">
										<div class="control-row">
											<label class="cell">{{ t("midjourney.aspectRatioLabel") }}</label>
											<app-hint-trigger [description]="t('midjourney.aspectRatioDescription')"></app-hint-trigger>
											<div class="cell main">
												<mat-form-field appearance="outline" subscriptSizing="dynamic">
													<mat-select formControlName="aspectRatio">
														<mat-option value="9:16">9:16</mat-option>
														<mat-option value="3:4">3:4</mat-option>
														<mat-option value="1:1">1:1</mat-option>
														<mat-option value="4:3">4:3</mat-option>
														<mat-option value="16:9">16:9</mat-option>
													</mat-select>
												</mat-form-field>
											</div>
										</div>
										<div class="control-row">
											<label class="cell">{{ t("midjourney.qualityLabel") }}</label>
											<app-hint-trigger [description]="t('midjourney.qualityDescription')"></app-hint-trigger>
											<div class="cell main">
												<mat-form-field appearance="outline" subscriptSizing="dynamic">
													<mat-select formControlName="quality">
														<mat-option value=".25">.25</mat-option>
														<mat-option value=".5">.5</mat-option>
														<mat-option value="1">1</mat-option>
														<mat-option value="2">2</mat-option>
													</mat-select>
												</mat-form-field>
											</div>
										</div>
										<div class="control-row">
											<label class="cell">{{ t("midjourney.seedLabel") }}</label>
											<app-hint-trigger [description]="t('midjourney.seedDescription')"></app-hint-trigger>
											<div class="cell main">
												<mat-form-field appearance="outline" subscriptSizing="dynamic">
													<input type="text" formControlName="seed" matInput [errorStateMatcher]="suppressAnyErrorStateMatcher" />
												</mat-form-field>
											</div>
										</div>
									</div>
									<div class="control-row">
										<mat-checkbox formControlName="tile">{{ t("midjourney.tileLabel") }}</mat-checkbox>
										<app-hint-trigger [description]="t('midjourney.tileDescription')"></app-hint-trigger>
									</div>
									<div class="grid-row">
										<div>
											<div class="control-row">
												<label>{{ t("midjourney.imageWeightLabel") }}</label>
												<app-hint-trigger [description]="t('midjourney.imageWeightDescription')"></app-hint-trigger>
											</div>
											<div class="control-row">
												<div class="cell main">
													<mat-slider min="0" max="3" step="0.2" discrete>
														<input formControlName="imageWeight" matSliderThumb />
													</mat-slider>
												</div>
											</div>
										</div>
										<div>
											<div class="control-row">
												<label>{{ t("midjourney.styleWeightLabel") }}</label>
												<app-hint-trigger [description]="t('midjourney.styleWeightDescription')"></app-hint-trigger>
											</div>
											<div class="control-row">
												<div class="cell main">
													<mat-slider min="0" max="1000" step="100" discrete>
														<input formControlName="styleWeight" matSliderThumb />
													</mat-slider>
												</div>
											</div>
										</div>
										<div>
											<div class="control-row">
												<label>{{ t("midjourney.characterWeightLabel") }}</label>
												<app-hint-trigger [description]="t('midjourney.characterWeightDescription')"></app-hint-trigger>
											</div>
											<div class="control-row">
												<div class="cell main">
													<mat-slider min="0" max="100" step="10" discrete>
														<input formControlName="characterWeight" matSliderThumb />
													</mat-slider>
												</div>
											</div>
										</div>
									</div>
								</ng-container>
								<ng-container *ngIf="form.controls.__type.value === ImagingProviderEnum.SD">
									<div class="grid-row">
										<div class="control-row">
											<label class="cell">{{ t("sd.numberOfImagesLabel") }}</label>
											<div class="cell main">
												<mat-form-field appearance="outline" subscriptSizing="dynamic">
													<mat-select formControlName="numberOfImages">
														<mat-option [value]="1">1</mat-option>
														<mat-option [value]="2">2</mat-option>
														<mat-option [value]="3">3</mat-option>
														<mat-option [value]="4">4</mat-option>
													</mat-select>
												</mat-form-field>
											</div>
										</div>
										<div class="control-row">
											<label class="cell">{{ t("sd.aspectRatioLabel") }}</label>
											<div class="cell main">
												<mat-form-field appearance="outline" subscriptSizing="dynamic">
													<mat-select [(ngModel)]="aspectRatio" (selectionChange)="recalculateDimensions()" [ngModelOptions]="{ standalone: true }">
														<mat-option value="9:16">9:16</mat-option>
														<mat-option value="3:4">3:4</mat-option>
														<mat-option value="1:1">1:1</mat-option>
														<mat-option value="4:3">4:3</mat-option>
														<mat-option value="16:9">16:9</mat-option>
													</mat-select>
												</mat-form-field>
											</div>
										</div>
										<div class="control-row">
											<label class="cell">{{ t("sd.size.label") }}</label>
											<div class="cell main">
												<mat-form-field appearance="outline" subscriptSizing="dynamic">
													<mat-select [(ngModel)]="imageSize" (selectionChange)="recalculateDimensions()" [ngModelOptions]="{ standalone: true }">
														<mat-option value="small">{{ t("sd.size.small") }}</mat-option>
														<mat-option value="normal">{{ t("sd.size.normal") }}</mat-option>
														<mat-option value="big">{{ t("sd.size.big") }}</mat-option>
													</mat-select>
												</mat-form-field>
											</div>
										</div>
									</div>
									<div class="grid-row">
										<div class="control-row">
											<label class="cell">{{ t("sd.dimensionsLabel") }}</label>
											<div class="cell main">
												<label>{{ form.controls.width.value }} x {{ form.controls.height.value }}</label>
											</div>
										</div>
										<div class="control-row">
											<label class="cell">{{ t("sd.numberOfInferenceStepsLabel") }}</label>
											<div class="cell main">
												<mat-form-field appearance="outline" subscriptSizing="dynamic">
													<input type="text" formControlName="numberOfInferenceSteps" matInput [errorStateMatcher]="suppressAnyErrorStateMatcher" />
												</mat-form-field>
											</div>
										</div>
										<div class="control-row">
											<label class="cell">{{ t("sd.guidanceScaleLabel") }}</label>
											<div class="cell main">
												<mat-form-field appearance="outline" subscriptSizing="dynamic">
													<input type="text" formControlName="guidanceScale" matInput [errorStateMatcher]="suppressAnyErrorStateMatcher" />
												</mat-form-field>
											</div>
										</div>
									</div>
								</ng-container>
								<ng-container *ngIf="form.controls.__type.value === ImagingProviderEnum.Midjourney">
									<div class="control-row">
										<label>{{ t("visualReferences.label") }}</label>
										<div>
											<button type="button" mat-icon-button [matMenuTriggerFor]="addVisualReferenceMenu">
												<mat-icon class="material-symbols-outlined">add</mat-icon>
											</button>
											<mat-menu #addVisualReferenceMenu="matMenu">
												<button mat-menu-item (click)="showImageLibrary()">
													<mat-icon>photo_library</mat-icon>
													<span>{{ t("visualReferences.menu.image") }}</span>
												</button>
												<button mat-menu-item (click)="addStyleReferenceCode()">
													<mat-icon>123</mat-icon>
													<span>{{ t("visualReferences.menu.code") }}</span>
												</button>
											</mat-menu>
										</div>
									</div>

									<div formArrayName="visualReferences" class="visual-references-wrapper">
										<div class="cell" *ngFor="let vrControl of form.controls.visualReferences.controls; index as i" [formGroupName]="i">
											<div class="vr-item-wrapper">
												<div class="image-wrapper">
													<img class="image" *ngIf="vrControl.get('imageUrl')?.value" [src]="vrControl.get('imageUrl')?.value" />
													<button class="button-remove" type="button" mat-mini-fab (click)="removeVisualReference(i)">
														<mat-icon class="material-symbols-outlined">close</mat-icon>
													</button>
												</div>
												<div class="controls-wrapper">
													<mat-form-field *ngIf="vrControl.get('isStyleReferenceCode')?.value === true" appearance="outline" floatLabel="always" subscriptSizing="dynamic">
														<mat-label>{{ t("visualReferences.externalUrlLabel") }}</mat-label>
														<input type="text" formControlName="externalUrl" matInput (input)="check($event)" />
													</mat-form-field>
													<mat-radio-group formControlName="roleId" class="row">
														<mat-radio-button
															*ngIf="vrControl.get('isStyleReferenceCode')?.value === false"
															[value]="GenerateImageTaskVisualReferenceRoleEnum.Image"
															[matTooltip]="t('visualReferences.role.imageTooltip')"
															><mat-icon class="material-symbols-outlined">image</mat-icon></mat-radio-button
														>
														<mat-radio-button [value]="GenerateImageTaskVisualReferenceRoleEnum.Style" [matTooltip]="t('visualReferences.role.styleTooltip')"
															><mat-icon class="material-symbols-outlined">format_paint</mat-icon></mat-radio-button
														>
														<mat-radio-button
															*ngIf="vrControl.get('isStyleReferenceCode')?.value === false"
															[value]="GenerateImageTaskVisualReferenceRoleEnum.Character"
															[matTooltip]="t('visualReferences.role.characterTooltip')"
															><mat-icon class="material-symbols-outlined">person</mat-icon></mat-radio-button
														>
													</mat-radio-group>
													<mat-form-field appearance="outline" floatLabel="always" subscriptSizing="dynamic">
														<mat-label>{{ t("visualReferences.weightLabel") }}</mat-label>
														<input type="text" formControlName="weight" matInput [errorStateMatcher]="suppressAnyErrorStateMatcher" />
													</mat-form-field>
												</div>
											</div>
										</div>
									</div>
								</ng-container>
							</div>
						</ng-container>
					</div>
				</mat-card-content>
				<mat-card-actions [align]="'end'">
					<button mat-flat-button color="primary" [disabled]="form.invalid || loading()">{{ t("buttonSubmit") }}</button>
				</mat-card-actions>
			</mat-card>
		</form>
	</div>
</ng-container>
