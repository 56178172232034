import { ChangeDetectionStrategy, Component } from '@angular/core';
import { GenerateImageTaskFormComponent } from '../../components/generate-image-task-form/generate-image-task-form.component';
import { GenerateImageTaskListComponent } from "../../components/generate-image-task-list/generate-image-task-list.component";

@Component({
	selector: 'app-generations',
	standalone: true,
	imports: [
		GenerateImageTaskFormComponent,
		GenerateImageTaskListComponent
	],
	templateUrl: './generations.component.html',
	styleUrl: './generations.component.less',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class GenerationsPageComponent {

}
