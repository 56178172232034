import { inject, Injectable, signal } from '@angular/core';
import { Observable, map, tap } from 'rxjs';
import { CreateGenerateImageTaskRequest, GenerateImageTask } from '../models/generate-image-task.models';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ApiPagedResponse, ApiResponse } from '../../../core/models/api/api.models';
import { WORKSPACE_API_URL_PREFIX } from '../../../shared/constants/api.constants';
import { instanceToPlain } from 'class-transformer';

@Injectable({
	providedIn: 'root'
})
export class ImagingService {

	private readonly _http = inject(HttpClient);

	private readonly _newGenerateImageTask = signal<GenerateImageTask | undefined>(undefined);
	newGenerateImageTask = this._newGenerateImageTask.asReadonly();

	getGenerateImageTaskById(id: number): Observable<GenerateImageTask> {
		return this._http.get<ApiResponse<GenerateImageTask>>(`${WORKSPACE_API_URL_PREFIX}/api/imaging/tasks/${id}`).pipe(
			map(v => GenerateImageTask.fromPlain(v.data))
		);
	}

	public getGenerateImageTasks(pageNumber: number, pageSize: number): Observable<ApiPagedResponse<GenerateImageTask>> {
		let params = new HttpParams()
			.set('pageNumber', pageNumber)
			.set('pageSize', pageSize);
		return this._http.get<ApiPagedResponse<GenerateImageTask>>(`${WORKSPACE_API_URL_PREFIX}/api/imaging/tasks`, { params: params }).pipe(
			map(v => {
				return {
					...v,
					data: v.data.map(i => GenerateImageTask.fromPlain(i))
				};
			})
		);
	}

	createGenerateImageTask(request: CreateGenerateImageTaskRequest): Observable<GenerateImageTask> {
		const body = instanceToPlain(request);
		return this._http.post<ApiResponse<GenerateImageTask>>(`${WORKSPACE_API_URL_PREFIX}/api/imaging/tasks`, body).pipe(
			map(v => GenerateImageTask.fromPlain(v.data)),
			tap(v => {
				this._newGenerateImageTask.set(v);
			})
		);
	}
}
