<ng-container *transloco="let t; prefix: 'integration.components.apiKeyList'">
	<div class="row">
		<mat-icon class="material-symbols-outlined">passkey</mat-icon>
		<span class="mat-body-large">{{ t("caption") }}</span>
	</div>

	<div class="widget-wrapper" *ngIf="data$ | async as data; else loading">
		<div class="toolbar-wrapper">
			<button mat-raised-button (click)="createApiKey()" [disabled]="data.length > 2">
				<mat-icon class="material-icons-outlined">add</mat-icon>
				{{ t("createButton") }}
			</button>
		</div>
		<mat-table [dataSource]="data" multiTemplateDataRows [fixedLayout]="true">
			<ng-container matColumnDef="value">
				<mat-header-cell *matHeaderCellDef>{{ t("apiKey.valueHeader") }}</mat-header-cell>
				<mat-cell *matCellDef="let row">{{ row.value }}</mat-cell>
			</ng-container>
			<ng-container matColumnDef="action">
				<mat-header-cell *matHeaderCellDef></mat-header-cell>
				<mat-cell *matCellDef="let row">
					<button type="button" mat-icon-button [cdkCopyToClipboard]="row.value" [matTooltip]="t('apiKey.copyButtonTooltip')">
						<mat-icon class="material-symbols-outlined">content_copy</mat-icon>
					</button>
					<button type="button" mat-icon-button (click)="deleteApiKey(row); $event.stopPropagation()" [matTooltip]="t('apiKey.deleteButtonTooltip')">
						<mat-icon class="material-symbols-outlined">delete</mat-icon>
					</button>
				</mat-cell>
			</ng-container>
			<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
			<mat-row matRipple *matRowDef="let row; columns: displayedColumns"></mat-row>
		</mat-table>
	</div>

	<ng-template #loading>
		<div class="loading-wrapper">
			<mat-progress-spinner mode="indeterminate" diameter="32"></mat-progress-spinner>
		</div>
	</ng-template>
</ng-container>
