import { ChangeDetectionStrategy, Component, DestroyRef, ElementRef, EventEmitter, inject, Input, Output, signal, ViewChild } from '@angular/core';
import { UploadedImage } from '../../models/uploaded-image.models';
import { ApiResponse } from '../../../../core/models/api/api.models';
import { HttpClient, HttpEvent, HttpEventType } from '@angular/common/http';
import { filter, finalize, fromEvent, map, Observable, switchMap, tap } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { WORKSPACE_API_URL_PREFIX } from '../../../../shared/constants/api.constants';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatIconModule } from '@angular/material/icon';
import { NgIf } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { TranslocoModule } from '@jsverse/transloco';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
	selector: 'app-image-upload',
	standalone: true,
	imports: [
		MatProgressBarModule,
		MatIconModule,
		NgIf,
		MatButtonModule,
		TranslocoModule,
		MatTooltipModule
	],
	templateUrl: './image-upload.component.html',
	styleUrl: './image-upload.component.less',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImageUploadComponent {
	@ViewChild('fileUpload') fileUploadElement!: ElementRef;

	@Input('accept') requiredFileType?: string;

	@Output('upload') contentUploadResult = new EventEmitter<UploadedImage>();

	private readonly _httpClient = inject(HttpClient);
	private readonly _destroyRef = inject(DestroyRef);

	progress = signal<number | undefined>(undefined);

	fileName?: string;

	upload$!: Observable<HttpEvent<ApiResponse<UploadedImage>>>;

	ngAfterViewInit(): void {

		this.upload$ = fromEvent<any>(this.fileUploadElement.nativeElement, 'change')
			.pipe(
				filter(e => {
					return e.target.files!.length > 0;
				}),
				tap(() => {
					this.progress.set(0);
				}),
				map(e => {
					const file: File = e.target.files[0];
					const formData = new FormData();
					formData.append("file", file);
					this.fileName = file.name;
					return formData;
				}),
				switchMap(data => {
					return this._httpClient.post<ApiResponse<UploadedImage>>(`${WORKSPACE_API_URL_PREFIX}/api/upload/image/file`, data,
						{
							reportProgress: true,
							observe: 'events'
						}).pipe(
							finalize(() => {
								this.reset();
								this.fileUploadElement.nativeElement.value = null;
							})
						);
				}),
				takeUntilDestroyed(this._destroyRef)
			);

		this.upload$.subscribe(event => {
			if (event.type == HttpEventType.UploadProgress) {
				const p = Math.round(100 * (event.loaded / event.total!));
				this.progress.set(p);
			}
			if (event.type == HttpEventType.Response) {
				this.contentUploadResult.emit(event.body?.data);
			}
		});
	}

	reset() {
		this.progress.set(undefined);
	}
}
