import { ChangeDetectionStrategy, Component, computed, effect, inject, signal, Signal } from '@angular/core';
import { ImagingService } from '../../services/imagine.service';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { GenerateImageTask } from '../../models/generate-image-task.models';
import { combineLatest, map, Observable } from 'rxjs';
import { AsyncPipe } from '@angular/common';
import { GenerateImageTaskComponent } from '../generate-image-task/generate-image-task.component';

@Component({
	selector: 'app-generate-image-task-list',
	standalone: true,
	imports: [
		AsyncPipe,
		GenerateImageTaskComponent
	],
	templateUrl: './generate-image-task-list.component.html',
	styleUrl: './generate-image-task-list.component.less',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class GenerateImageTaskListComponent {

	private readonly _imagingService = inject(ImagingService);

	tasks$: Observable<GenerateImageTask[]>;

	constructor() {
		const existing$ = this._imagingService.getGenerateImageTasks(1, 20).pipe(
			map(v => v.data),
		);
		const new$ = toObservable(this._imagingService.newGenerateImageTask);
		this.tasks$ = combineLatest([
			existing$,
			new$
		]).pipe(
			takeUntilDestroyed(),
			map(([e, n]) => {
				if (n && !e.some(i => i.id === n!.id)) {
					e.unshift(n);
				}
				return e;
			})
		)
	}
}
