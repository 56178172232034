import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { IConfigurationService } from '../../core/services/configuration/configuration.service';
import { WORKSPACE_API_URL_PREFIX } from '../constants/api.constants';
import { switchMap } from 'rxjs';

export const setApiUrlInterceptor: HttpInterceptorFn = (req, next) => {
	const configService = inject(IConfigurationService);
	if (!req.url.startsWith(WORKSPACE_API_URL_PREFIX)) {
		return next(req);
	}
	return configService.getConfiguration().pipe(
		switchMap(v => {
			const apiRequest = req.clone({
				url: req.url.replace(WORKSPACE_API_URL_PREFIX, v.endpoints.workspaceApiRoot)
			});
			return next(apiRequest);
		})
	)
};
