import { Dialog } from '@angular/cdk/dialog';
import { Directive, ElementRef, HostListener, inject, input } from '@angular/core';
import { ImageModalWrapperComponent } from '../components/image-modal-wrapper/image-modal-wrapper.component';

@Directive({
	selector: '[appViewImage]',
	standalone: true
})
export class ViewImageDirective {

	private element = inject<ElementRef<HTMLElement>>(ElementRef);
	private readonly _dialog = inject(Dialog);

	appViewImage = input<string>();

	@HostListener("click")
	showImage(): void {
		const dialogRef = this._dialog.open<void>(ImageModalWrapperComponent, {
			data: this.appViewImage(),
			backdropClass: "strong-blur-backdrop"
		});
	}
}
